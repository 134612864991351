@import 'carbon-components/scss/globals/scss/_vars.scss';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

header {
  .bx--combo-box {
    background: none;
    // color: #fff;
    border-bottom: 0;

    &:hover {
      background: none;
    }

    .bx--text-input {
      color: #fff;
      border-bottom: 0;
    }

    .bx--text-input:disabled {
      color: #c6c6c6;
    }

    .bx--list-box__selection > svg,
    .bx--list-box__menu-icon > svg {
      fill: #fff;
    }
  }

  .logo {
    height: 24px;
    margin-right: 0.5rem;

    path {
      fill: #fb4425;
      stroke-width: 0.385;
    }
  }

  .account-dashboard {
    display: flex;
    align-items: center;
    color: #fff;
  }
}

.bx--header-panel {
  bottom: inherit;
}

.user-details {
  padding: 1rem;
}

.user-details a {
  margin-top: 1rem;
}

nav {
  display: none;
  background: #fff;
  position: fixed;
  top: 48px;
  left: 0;
  right: 0;
  min-height: 70px;
  max-height: calc(100vh - 48px);
  z-index: 10;
  overflow-y: auto;
  box-shadow: 0 1px 3px #999;
  transition: all 0.15s ease-out;
  opacity: 0;
  transform: translate3d(0, -3rem, 0);

  &.rendered {
    display: block;
  }

  &.expanded {
    display: block;
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  .categories {
    display: flex;
    flex-wrap: wrap;
    padding-right: 70px;

    & > div {
      padding: 1rem;
    }

    ul {
      margin-top: 0.5rem;
      padding-left: 0.5rem;
    }

    li {
      padding: 0.25rem 0;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .no-dashboards {
    padding: 16px;
    margin-top: 10px;
  }
}

main {
  height: 100vh;
  padding: 48px 0 0 0;
  // background: #f4f4f4;
}

.frame {
  height: calc(100vh - 50px);
  width: 100%;
  background: #f4f4f4;
}

.placeholder {
  display: none;
  position: absolute;
  top: 48px;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease-out;
  opacity: 0;

  &.rendered {
    display: flex;
  }

  &.visible {
    opacity: 1;
  }

  svg {
    width: 300px;

    path {
      fill: #fb4425;
      stroke-width: 0.385;
    }
  }

  p {
    text-align: center;
    font-size: 24px;
  }
}

.loader-container {
  display: none;
  padding: 1rem;
  overflow: hidden;
  transition: all 0.3s ease-out;
  opacity: 0;

  &.rendered {
    display: block;
  }

  &.visible {
    opacity: 1;
  }

  .loader {
    transform-origin: center center;
    border: 2px solid #eee;
    border-right-color: #fb4425;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    animation: circle-loader 1s infinite ease-out;
  }

  nav & {
    position: absolute;
    top: 0;

    &.position--right {
      right: 0;
    }

    &.position--center {
      right: calc(50% - 35px);
    }
  }
}

@keyframes circle-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  strong {
    display: inline-block;
    font-size: 24px;
    padding: 10px 23px 10px 0;
    margin-right: 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
  }
}

.no-accounts {
  display: inline-block;
  margin-right: 1rem;
}

.account-name {
  display: inline-block;
  margin-right: 1rem;
}

.landing-loading {
  position: absolute;
  right: 0;
}

.landing-failed {
  padding: 1rem;
}

.filters {
  padding: 0.5rem 0.5rem 0;
  display: flex;
}

.filter {
  padding: 0.5rem 0.5rem 0;
}

.widgets {
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
}

.widget {
  padding: 0.5rem;

  &__wrapper {
    padding: 1rem;

    &.loading {
      animation: tile-loading 2s infinite;
    }
  }

  h2 {
    font-size: 1.25rem;
    padding-bottom: 1rem;
  }
}

@keyframes tile-loading {
  0% {
    background-color: #f4f4f4;
  }
  50% {
    background-color: #e5e5e5;
  }
  100% {
    background-color: #f4f4f4;
  }
}

.vega-embed {
  width: 100%;
  display: block !important;
  cursor: pointer !important;

  & canvas {
    display: block;
  }
}

.error-text {
  color: $text-error;
}

.notification {
  display: flex;
  align-items: center;
  justify-content: center;
}